<template>
  <div v-for="feature in features" v-bind:key="feature" class="features-list">
    <Tooltip :text="feature.feature">
      <img
        :src="getIcon(feature.feature)"
        alt="Ticket feature icon"
        class="mr-4"
      />
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/common/Tooltip";

export default {
  name: "TicketsFeaturesIcons",
  props: {
    features: {
      type: Array,
      default: () => [],
    },
  },
  components: { Tooltip },
  data: () => ({}),
  computed: {},
  methods: {
    getIcon(name) {
      let featureImg;
      switch (name) {
        case "Access to virtual conference": {
          featureImg = "virtual_conference";
          break;
        }
        case "Access to live conference": {
          featureImg = "live_conference";
          break;
        }
        case "After-party pass": {
          featureImg = "party";
          break;
        }
        case "T-shirt": {
          featureImg = "t_shirt";
          break;
        }
        case "Exclusive Access to Executive session": {
          featureImg = "executive_session";
          break;
        }
        case "Executive Lounge": {
          featureImg = "lounge";
          break;
        }
        default: {
          featureImg = "live_conference";
          break;
        }
      }
      return require(`../../assets/img/icons/features/${featureImg}.svg`);
    },
  },
};
</script>

<style scoped>
.features-list {
  display: inline-block;
}
</style>
