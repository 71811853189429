<template>
  <div></div>
  <div
    class="code mb-5"
    :class="{ 'sponsor-code': ticket.level === 'sponsor' }"
  >
    <div
      v-if="ticket.level === 'sponsor'"
      class="code__badge"
      :style="
        ticket.claimed === ticket.limit ? 'background: rgb(168 171 171);' : null
      "
    >
      <svg
        width="32"
        height="30"
        viewBox="0 0 32 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 27.0511C32 27.7987 31.7364 28.3689 31.2093 28.7618C30.6822 29.1548 30.0353 29.3512 29.2686 29.3512H2.73136C1.96466 29.3512 1.31776 29.15 0.790656 28.7474C0.263552 28.3449 0 27.7795 0 27.0511C0 26.4761 0.172507 25.8915 0.51752 25.2973L13.7718 2.18141C14.4043 1.1272 15.1518 0.600098 16.0144 0.600098C16.8769 0.600098 17.6053 1.1272 18.1995 2.18141L31.4825 25.3261C31.8275 25.9394 32 26.5144 32 27.0511ZM17.9982 12.3593V8.16164H14.0018V12.3593C14.0018 12.6277 14.021 12.8816 14.0593 13.1212C14.0976 13.3608 14.1503 13.6291 14.2174 13.9262C14.2845 14.2233 14.3372 14.4773 14.3756 14.6881L15.1231 19.3458H16.8194L17.5957 14.6881C17.634 14.4965 17.6915 14.2473 17.7682 13.9406C17.8449 13.6339 17.9024 13.3608 17.9407 13.1212C17.979 12.8816 17.9982 12.6277 17.9982 12.3593ZM17.9982 23.486C17.9982 22.9301 17.8017 22.4605 17.4088 22.0772C17.0159 21.6938 16.5415 21.5022 15.9856 21.5022C15.4489 21.5022 14.9841 21.6938 14.5912 22.0772C14.1983 22.4605 14.0018 22.9301 14.0018 23.486C14.0018 24.0418 14.1983 24.5162 14.5912 24.9092C14.9841 25.3021 15.4489 25.4986 15.9856 25.4986C16.5415 25.4986 17.0159 25.3021 17.4088 24.9092C17.8017 24.5162 17.9982 24.0418 17.9982 23.486Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="code__wrapper">
      <dl style="max-width: 300px">
        <dt class="code__title">
          <img
            src="../../assets/img/icons/ticket.svg"
            alt="ticket icon"
            class="mx-2"
          />
          {{ ticket.title }}
        </dt>
        <dd class="code__text">
          Code: <span>{{ ticket.id.toUpperCase() }}</span>
        </dd>
        <dd class="code__text">
          Features:
          <span>
            <TicketsFeaturesIcons :features="ticket.description" />
          </span>
        </dd>
        <dd class="code__text">
          <!--          simulation of unlimited ticket, if limit more than 100-->
          Claimed:
          <span>
            {{ ticket.claimed }}/{{
              ticket.limit > 100 ? "unlimited" : ticket.limit
            }}
          </span>
        </dd>
      </dl>
      <div class="code__actions">
        <Tooltip
          :text="copied ? 'Link copied to clipboard' : 'Click to copy link'"
        >
          <img
            src="../../assets/img/icons/copy.svg"
            alt="Copy"
            @click="copyCode(ticket.id)"
          />
        </Tooltip>
        <Tooltip
          :text="
            ticket.claimed === ticket.limit
              ? 'You can’t invite more attendees'
              : 'Click to invite attendees'
          "
        >
          <img
            src="../../assets/img/icons/message-add.svg"
            alt="Invite attendees icon"
            :class="{
              'code__disabled-img': ticket.claimed === ticket.limit,
            }"
            @click="redirect(ticket, 'invite')"
          />
        </Tooltip>
        <Tooltip
          :text="
            ticket.claimed === ticket.limit
              ? 'You can’t register more attendees'
              : 'Click to register attendees'
          "
        >
          <img
            src="../../assets/img/icons/user-add.svg"
            alt="Register attendees icon"
            :class="{
              'code__disabled-img': ticket.claimed === ticket.limit,
            }"
            @click="redirect(ticket, 'register')"
          />
        </Tooltip>
      </div>
      <div v-if="ticket.level === 'sponsor'" class="sponsor-warn">
        These tickets are for company representatives only. Please take note
        before sharing these.
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from "@/components/common/Tooltip";
import TicketsFeaturesIcons from "./TicketsFeaturesIcons";

export default {
  name: "Code",
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    redirect: {
      type: Function,
      default: () => {},
    },
  },
  components: { TicketsFeaturesIcons, Tooltip },
  data: () => ({
    copied: false,
  }),
  methods: {
    async copyCode(code) {
      const link =
        process.env.VUE_APP_BASE_URL.split("api/")[0] +
        "#/" +
        this.$route.params.id +
        "/join/" +
        code;
      try {
        await navigator.clipboard.writeText(link);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch ($e) {
        console.log("Cannot copy");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.code {
  width: 100%;
  display: flex;
  background-color: $light-gray;
  padding: 20px;
  position: relative;

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    letter-spacing: 0.02em;
    color: $dark-gray;
  }

  &__title {
    font-weight: $sarabunBold;
  }

  &__badge {
    width: 70px;
    height: 40px;
    background: $blue;
    position: absolute;
    top: -5%;
    left: -3%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    span {
      margin-left: 25px;
      font-weight: $sansReg;
      letter-spacing: 0.05em;
      color: $gray;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5%;

    img {
      cursor: pointer;
    }
  }

  &__disabled-img {
    cursor: not-allowed;
    filter: brightness(3) invert(1);
  }

  @media (min-width: $media-xs) {
    &__wrapper {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__badge {
      top: -7%;
    }

    &__actions {
      flex-direction: column;
      margin: 0 3% 0 0;
    }
  }
  @media (min-width: $media-sm) {
    &__badge {
      width: 64px;
      height: 112px;
      position: static;
      transform: translateY(-40%);
      align-items: flex-start;
      margin-right: 24px;
      padding-top: 1%;
    }
  }

  @media (min-width: $media-md) {
    &__badge {
      position: absolute;
      left: 5%;
      top: 0;
      height: 40px;
      width: 100px;
      transform: translateY(-60%);
    }
  }
  @media (min-width: $media-lg) {
    padding: 25px;

    /*&__badge {*/
    /*width: 72px;*/
    /*transform: translateY(-50%);*/
    /*}*/
  }
}

.code.sponsor-code {
  border: 1px solid #1d1c1c;
  background: none;
  margin-top: 32px;
}

.sponsor-warn {
  font-size: 14px;
  flex-basis: 100%;
  background-color: $light-gray;
  padding: 8px 16px;
  margin-top: 17px;

  @media (min-width: $media-sm) {
    font-size: 18px;
  }
}
</style>
